<template>
  <div>
    <!--begin::User-->
    <div>

      <h3 class="card-label">
        {{ $t('MENU.supplier_permissions') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <!--              <b-form-checkbox id="created_by_user_id" name="created_by_user_id" value="1" unchecked-value="0"-->
                <b-form-checkbox id="created_by_user_id" name="created_by_user_id" value="1" disabled
                                 v-model="data.access_supplier.created_by_user_id"
                >{{ $t('supplier_permissions.suppliers_created_by_the_user') }}
                </b-form-checkbox>

              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <p></p>
                <b-form-checkbox id="supplier_groups" name="supplier_groups" value="1" unchecked-value="0"
                                 v-model="data.access_supplier.supplier_groups"
                >{{ $t('supplier_permissions.suppliers_belonging_to_the_groups_that_the_user_has_access_to') }}
                </b-form-checkbox>

                <label>{{ $t('supplier_permissions.groups') }}</label>
                <multiselect v-model="groups_objs"
                             :placeholder="$t('supplier_permissions.groups')"
                             label="name"
                             track-by="id"
                             :options="groups"
                             @input="getMultiSelectGroups"
                             :multiple="true"
                             :class="validation && validation.supplier_special_group ? 'is-invalid' : ''"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.supplier_special_group"
                      class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.supplier__group[0] }}
                                    </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mt-md-0 mt-5">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <b-form-checkbox id="user_branches" name="user_branches" value="1" unchecked-value="0"
                                 v-model="data.access_supplier.user_branches"
                >{{ $t('supplier_permissions.suppliers_belonging_to_the_branches_that_the_user_has_access_to') }}
                </b-form-checkbox>
                <label>{{ $t('supplier_permissions.branches') }}</label>
                <multiselect v-model="branches_objs"
                             :placeholder="$t('supplier_permissions.branches')"
                             label="name"
                             track-by="id"
                             :options="branches"
                             @input="getMultiSelectBranch"
                             :multiple="true"
                             :class="validation && validation.supplier_special_branch ? 'is-invalid' : ''"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.supplier_special_branch"
                      class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.supplier_special_branch[0] }}
                                    </span>
              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <b-form-checkbox id="users_supplier" name="users_supplier" value="1" unchecked-value="0"
                                 v-model="data.access_supplier.users_supplier"
                >{{ $t('supplier_permissions.suppliers_belonging_to_the_users_that_the_user_has_access_to') }}
                </b-form-checkbox>
                <label>{{ $t('supplier_permissions.users') }}</label>
                <multiselect v-model="users_objs"
                             :placeholder="$t('supplier_permissions.users')"
                             label="name"
                             track-by="id"
                             :options="users"
                             @input="getMultiSelectUsers"
                             :multiple="true"
                             :class="validation && validation.supplier_special_user ? 'is-invalid' : ''"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.supplier_special_user"
                      class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.supplier_special_user[0] }}
                                    </span>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save()">
              {{ $t('save') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-user",
  data() {
    return {
      mainRoute: 'user/access_supplier',
      mainRouteDependency: 'base/dependency',
      user_id: this.$route.params.user_id ? this.$route.params.user_id : null,
      data: {
        supplier_special_branch: [],
        supplier_special_user: [],
        supplier_special_group: [],
        access_supplier: {
          created_by_user_id: 1,
          user_branches: 0,
          supplier_groups: 0,
          users_supplier: 0,
        }
      },
      validation: null,

      branches_objs: null,
      users_objs: null,
      groups_objs: null,

      branches: [],
      users: [],
      groups: [],

      full_name: null,
    };
  },
  watch: {},
  methods: {
    save() {
      this.create();
    },

    create() {
      if (this.user_id)
        ApiService.put(this.mainRoute + '/' + this.user_id, {
          ...this.data,
        }).then((response) => {
          this.$router.push({name: 'users.index'})
          this.validation = null;
          this.$successAlert(response.data.message);
        })
            .catch((error) => {
              this.validation = error.response ? error.response.data.errors : null;
            });
    },


    async getData(id) {
      await ApiService.get(`users/${id}`).then((response) => {
        this.full_name = response.data.data.full_name;
        this.data.access_supplier = response.data.data.access_supplier ? response.data.data.access_supplier : {
          created_by_user_id: 1,
          user_branches: 0,
          supplier_groups: 0,
          users_supplier: 0,
        };

        this.data.supplier_special_branch = response.data.data.supplier_special_branch ? response.data.data.supplier_special_branch : [];
        if (response.data.data.supplier_special_branch)
          this.branches_objs = this.branches.filter((row) => response.data.data.supplier_special_branch.includes(row.id));

        this.data.supplier_special_user = response.data.data.supplier_special_user ? response.data.data.supplier_special_user : [];
        if (response.data.data.supplier_special_user)
          this.users_objs = this.users.filter((row) => response.data.data.supplier_special_user.includes(row.id));

        this.data.supplier_special_group = response.data.data.supplier_special_group ? response.data.data.supplier_special_group : [];
        if (response.data.data.supplier_special_group)
          this.groups_objs = this.groups.filter((row) => response.data.data.supplier_special_group.includes(row.id));


        if (this.data.access_supplier.created_by_user_id == 0) {
          this.data.access_supplier.created_by_user_id = 1;
        }
      });
    },


    getMultiSelectBranch() {
      this.data.supplier_special_branch = [];
      this.branches_objs.filter((row) => {
        this.data.supplier_special_branch.push(row.id);
      });
    },
    getMultiSelectUsers() {
      this.data.supplier_special_user = [];
      this.users_objs.filter((row) => {
        this.data.supplier_special_user.push(row.id);
      });
    },
    getMultiSelectGroups() {
      this.data.supplier_special_group = [];
      this.groups_objs.filter((row) => {
        this.data.supplier_special_group.push(row.id);
      });
    },

    async getBranches() {
      await ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    async getUsers() {
      await ApiService.get(this.mainRouteDependency + "/users").then((response) => {
        this.users = response.data.data;
      });
    },
    async getGroups() {
      await ApiService.get(this.mainRouteDependency + "/groups").then((response) => {
        this.groups = response.data.data;
      });
    },


  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("MENU.user_Management"), route: '/users/users'},
      // {title: this.$t("MENU.supplier_permissions")},
      // {title: (this.idEditing ? this.$t('update') : this.$t('create'))}
    ]);

    let promise1 = this.getBranches();
    let promise2 = this.getUsers();
    // let promise3 = this.getGroups();
    Promise.all([promise1, promise2]).then(() => {
      if (this.user_id) {
        let promise3 = this.getData(this.user_id);
        Promise.all([promise3]).then(() => {
          this.$store.dispatch(SET_BREADCRUMB, [
            {title: this.$t("MENU.user_Management"), route: '/users/users'},
            {title: this.full_name},
            {title: this.$t("MENU.supplier_permissions")},
          ]);
        })
      }
    })

  },
};
</script>


